import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "content-of-infrastructure"
    }}>{`Content of infrastructure`}</h1>
    <ul>
      <li parentName="ul">{`Kubernetes`}</li>
      <li parentName="ul">{`Docker`}</li>
      <li parentName="ul">{`Nexus`}</li>
      <li parentName="ul">{`Docs`}</li>
    </ul>
    <h1 {...{
      "id": "kubernetes-information"
    }}>{`Kubernetes Information`}</h1>
    <p>{`hoe switch je makkelijk tussen een context:
kubectl config use-context `}{`[clusternaam]`}</p>
    <p>{`get namespaces
kubectl get ns`}</p>
    <p>{`get services
kubectl -n prod describe service `}{`[service naam]`}</p>
    <p>{`get pods
kubectl -n prod get po`}</p>
    <p>{`enkele pod
kubectl -n prod get po `}{`[pod name]`}{` -o json,wide,etc`}</p>
    <p>{`get logs (-f om logs te tailen dus wat er LIVE gebeurt zie je dan)
kubectl logs `}{`[pod naam]`}</p>
    <p>{`om de appsettings te zien van een pod (in een pod zit een project)
kubectl describe pods `}{`[pod name]`}</p>
    <p>{`ing == ingress
kubectl get ing
kubectl describe ing `}{`[name]`}</p>
    <p>{`om een pod te herstarten delete je de pod
kubectl delete pod `}{`[pod name]`}</p>
    <p>{`kubectl get deploy (om de deployments op te halen)
kubectl config view --minify (check of het de goeie is)`}</p>
    <h1 {...{
      "id": "infrastructure-known-issues"
    }}>{`Infrastructure Known issues`}</h1>
    <p>{`Kubernetes documentatie (cheat sheet) `}<a parentName="p" {...{
        "href": "https://kubernetes.io/docs/reference/kubectl/quick-reference/"
      }}>{`https://kubernetes.io/docs/reference/kubectl/quick-reference/`}</a></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Kubernetes (AKS)`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Website is niet meer beschikbaar terwijl kube cluster draait`}</p>
            <ul parentName="li">
              <li parentName="ul">{`De loadbalancer op Azure geeft aan dat het cluster niet healty is in Azure insights`}</li>
              <li parentName="ul">{`Dit komt door:`}<ul parentName="li">
                  <li parentName="ul">{`Version upgrade from 1.2* to 1.24.x`}</li>
                  <li parentName="ul">{`Ingress not exposed:`}<ul parentName="li">
                      <li parentName="ul">{`Solution: Check the nginx ingress controller service describe output. If the "service.beta.kubernetes.io/azure-load-balancer-health-probe-request-path=/healthz" annotation is missing the LB health probes will possibly fail. full link to the solution `}<a parentName="li" {...{
                          "href": "https://learn.microsoft.com/en-us/answers/questions/1008699/aks-1-24-ingress-not-exposed"
                        }}>{`https://learn.microsoft.com/en-us/answers/questions/1008699/aks-1-24-ingress-not-exposed`}</a></li>
                    </ul></li>
                </ul></li>
            </ul>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Kubernetes api certificaten verlopen, nodes kunnen geen netwerkverbinding maken`}</p>
            <ul parentName="li">
              <li parentName="ul">
                <p parentName="li">{`Oorzaak`}</p>
                <ul parentName="li">
                  <li parentName="ul">{`Service principal secret is verlopen.`}</li>
                </ul>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Oplossing: Reset app credentials (`}<a parentName="p" {...{
                    "href": "https://learn.microsoft.com/en-us/azure/aks/update-credentials"
                  }}>{`link to solution`}</a>{`)`}</p>
                <pre parentName="li"><code parentName="pre" {...{
                    "className": "language-shell"
                  }}>{`az login

RESOURCE_GROUP=<myResourceGroup>
CLUSTER_NAME=<myAKSCluster>

SP_ID=$(az aks show --resource-group $RESOURCE_GROUP --name $CLUSTER_NAME \\
--query servicePrincipalProfile.clientId -o tsv)

SP_SECRET=$(az ad app credential reset --years 2 --id "$SP_ID" --query password -o tsv)

az aks update-credentials \\
--resource-group $RESOURCE_GROUP \\
--name $CLUSTER_NAME \\
--reset-service-principal \\
--service-principal "$SP_ID" \\
--client-secret "\${SP_SECRET}"

`}</code></pre>
              </li>
            </ul>
          </li>
          <li parentName="ul">
            <p parentName="li">{`x509: certificate has expired or is not yet valid `}</p>
            <ul parentName="li">
              <li parentName="ul">{`Certificates of the Kubernetes cluster needs to be updated. In order to update the certificate following steps need to be done in Azure.`}<ul parentName="li">
                  <li parentName="ul">{`Rotate certificate with:`}<pre parentName="li"><code parentName="pre" {...{
                        "className": "language-shell"
                      }}>{`az aks rotate-certs -g [resource-group-name] -n [cluster-name]
# This will take about 10-20 minutes. KUBERNETES WILL NEED TO RESTART SO INFORM THE CUSTOMER
`}</code></pre></li>
                  <li parentName="ul">{`Get credentials in kube config with: `}<pre parentName="li"><code parentName="pre" {...{
                        "className": "language-shell"
                      }}>{`az aks get-credentials -g [resource-group-name] -n [cluster-name] --overwrite-existing
`}</code></pre><blockquote parentName="li">
                      <p parentName="blockquote">{`If you have a RBAC-enabled cluster built after March 2022 it is enabled with certificate auto-rotation.`}</p>
                    </blockquote></li>
                </ul></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      